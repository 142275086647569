/* stylelint-disable */
html,
body,
#root {
  width: 100%;
  height: 100%;
}
/* stylelint-enable */

* {
  box-sizing: border-box;
}
